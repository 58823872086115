import React, { useEffect, useState, memo, FunctionComponent, useMemo, useCallback } from 'react';
import { useParams, useHistory, useLocation } from "react-router";
import { compose, transduce, map, toArray } from "transducist";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { GET_ALL_FORMS_DATA, GET_ACTIVITY_DATA_FOR_FORM, GET_ALL_FORM_FIELDS, GET_FORMS, GET_ACTIVITY_HEADERS_FOR_FORM } from '../../utils/queries';
import client from "../../utils/apolloClient";
import BlockCell from '../../components/BlockCell';
import { StatusWithDelayed, DateTimeFormat, RenderImage } from '../../components/table/Commonfunctions';
import DefaultCells from '../../components/DefaultCells';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
import ThinTable from '../../components/ThinTable/ThinTable';
import { useStore } from '../../models/ProvideModel';
import config from '../../configs/clientConfig';
import moment from 'moment';
import useAccordion from '../../components/filter/UseAccordionHook';
import { DefaultList } from '../../components/filter/DefaultAccordianRender';
import { observer } from 'mobx-react-lite';
import RadioButton from '../../components/filter/RadioButton';
import { SelectedState } from '../../components/filter/SelectedState';
import useStyles from './FormDataAggregateStyles'
import RightArrow from '../../assets/images/right-arrow.png'
import { putAsync } from 'csp-with-ts';
import TableMultiselect from '../../components/fatTable/TableMultiselect';
import { sortingStrategyPosition } from '../../utils/utils';

/* Should use store.params.toPath and all that but not importing the whole
   observable for just that. */
const __SECTION = "form-data";
const __MAX = 10000;
const __sortingStrategy = sortingStrategyPosition("position");

interface IQueryFilterParams {
  where: Record<string, any>;
  order_by: { [k: string]: "asc" | "desc" }[];
}

export interface IColumnOptions {
  id: string;
  name: string;
  value?: string;
  Component?: FunctionComponent<{ [K: string]: any; }>;
  Cell?: FunctionComponent;
  print?: boolean;
  accessor?: string;
  minWidth?: number;
  headerObj?: string;
  excelExport?: Function;
  isExcelField?: boolean;
  inputType?: string;
  group?: string;
  excelExportTitle?: string;
  filter?: Function;
};

export function useCustomQueryParameters(
  formDataColumns: IColumnOptions[],
  filters: URLSearchParams
) {
  // const { blockId } = useParams();               // Use when block_id is a fk
  const [param, setParam] = useState<IQueryFilterParams>();
  const store = useStore();
  useEffect(() => {
    const sort = filters.getAll("sort");
    const descSort = filters.getAll("sortDesc");
    let variables: IQueryFilterParams = {
      where: { block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } } },
      order_by: descSort.length === 0 && sort.length === 0 ? [{ unit_activity_id: "asc" }] : [],
    };
    for (const { id: field, filter } of formDataColumns) {
      if (sort.length > 0 && sort.includes(field)) {
        variables["order_by"].push({ [field]: "asc" });
      }
      if (descSort.length > 0 && descSort.includes(field)) {
        variables["order_by"].push({ [field]: "desc" });
      }
      const value = filters.getAll(field);
      if (value.length > 0) {
        if (filter) {
          variables["where"] = filter === formComplex ?
            {
              ...(variables["where"] || {}),
              ...filter(value[0], store.auth.userId)
            }
            : filter === snagBool ? {
              ...(variables["where"] || {}),
              ...filter.apply(null, value)
            } : {
                ...(variables["where"] || {}),
                [field]: { ...filter.apply(null, value) },
              };
        } else {
          if (value.length === 1) {
            const singleVal = value[0];
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _eq: singleVal },
            };
          } else {
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _in: [...value] },
            };
          }
        }
      }
    }
    setParam({ ...variables });
  }, [filters]);
  return param;
}


function delta(input1: string, input2: string): { _lt: string; _gt: string } {
  if (!(input1 && input2)) {
    throw new Error("date type filters must define a range");
  }
  const dates = [input1, input2].map((d) => moment(d));
  return {
    _lt: moment.max(...dates).endOf('day').toISOString(),
    _gt: moment.min(...dates).toISOString(),
  };
}

function range(input: string): { _gt?: number; _lt?: number } {
  const [clause, value] = input.split(/([0-9]+)/);
  switch (clause) {
    case "lt":
      return { _lt: Number.parseInt(value) };
    case "gt":
    default:
      return { _gt: Number.parseInt(value) };
  }
}

//          Doesn't work because attachment is an empty string instead of null
/* function bool(input: string): { _is_null: boolean } {
 *   return { _is_null: input === "true" };
 * } */

function bool(input: string): { _neq: string; } | { _eq: string; } {
  return input === "false" ? { _eq: "" } : { _neq: "" };
}

function snagBool(input: string): { snag_counts?: {}; _not?: { snag_counts: {} } } {
  return input === "false" ? { _not: { snag_counts: {} } } : { snag_counts: {} };
}

function formComplex(input: string, user: string) {
  switch (input) {
    case 'edit': return { _or: [{ current_user_id: { _eq: user }, form_permission: { _contains: { action: "RW" } } }, { current_user_id: { _eq: user }, form_permission: { _contains: { action: "WRITE" } } }] };
    default: return {};
  }
}

interface ICustomPredicateContext {
  row: { [K: string]: any; };
  predicateKey: string;
  predicateKeyword: string;
  predicate?: (val: any, keyword: string) => boolean;
};
const customProcessExcelField = ({ onTrue, onFalse }: { onTrue?: (row: { [K: string]: any; }) => any, onFalse: (row: { [K: string]: any; }) => any }) => ({ row, predicateKey, predicateKeyword, predicate }: ICustomPredicateContext) => {
  const decisionValue = predicate ? predicate(row[predicateKey], predicateKeyword) : (row[predicateKey] === predicateKeyword);
  if (decisionValue) {
    if (onTrue) { return onTrue(row); }
    return "N/A";
  }
  else {
    return onFalse(row);
  }
};

// TODO do some cool shit with the prior knowledge of inputTypes. You can change
// the Cell at least.
const inputTypes = {
  "photo": (col: string) => ({ Cell: RenderImage, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].map(v => `${config.baseURL}large/${v}`).join('\n') }),
  "date": (col: string) => ({ Cell: DateTimeFormat, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].length && moment(elt[col]).format("YYYY-MM-DD") }),
  "time": (col: string) => ({ Cell: DateTimeFormat, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].length && moment(elt[col]).format("HH:mm") }),
  "datetime": (col: string) => ({ Cell: DateTimeFormat, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].length && moment(elt[col]).format("YYYY-MM-DD HH:mm") })
};
const __defaultExport = (col: string) => ({ isExcelField: true, excelExport: (elt: any) => elt[col] });

export const FormDataColumns: IColumnOptions[] = [
  { id: "block_id", value: "block", accessor: "block.id", name: "Block", print: true, Cell: BlockCell, headerObj: "block", minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['block']['title'], group: "Unit Activity", Component: TableMultiselect, },
  { id: "floor_idx", value: "floor_name", name: "Floor", print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['floor_name'], group: "Unit Activity", Component: TableMultiselect, },
  /* { id: "precedence", value: "precedence", name: "Precedence", isVisible: false, Component: TableMultiselect, print: false, minWidth: 100, isExcelField: false, excelExport: (elt: any) => elt['precedence'] }, */
  { id: "activity_type_id", value: "activity_name", name: "Activity Name", print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['activity_name'], group: "Unit Activity", Component: TableMultiselect, },
  { id: "unit_id", value: "unit_name", name: "Unit Title", print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_name'], group: "Unit Activity", Component: TableMultiselect, },
  /* { id: "pour_number", name: "Pour", Component: TableMultiselect, print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['pour_number'] }, */
  { id: "unit_type_id", value: "unit_type_title", name: "Unit Type", print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_type_title'], group: "Unit Activity", Component: TableMultiselect, },
  { id: "current_user_name", name: "Currently With", Component: TableMultiselect, print: true, isExcelField: true, excelExport: (row: any) => customProcessExcelField({ onFalse: (elt: any) => elt['current_user_name'] })({ row, predicateKey: "status", predicateKeyword: "8_done" }) },
  { id: "phase_id", name: "Phase", value: "phase_title", minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['phase_title'], group: "Unit Activity", Component: TableMultiselect, },
  { id: "space_type_id", name: "Space Type", value: "space_type_title", isExcelField: true, excelExport: (elt: any) => elt['space_type_title'], group: "Unit Activity", Component: TableMultiselect, },
  /* { id: "current_step", name: "Step", Cell: StepNameDetails, print: true, isExcelField: true, excelExport: (elt: any) => elt['current_step'] }, */
  { id: "status_desc", name: "Activity Status", Cell: StatusWithDelayed, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['status'] === '1_planned' ? 'Not Started' : elt['status'] === '3_active' ? 'On Going' : elt['status'] === '8_done' ? 'Completed' : 'Error', group: "Unit Activity", Component: TableMultiselect, },
  /* { id: "current_user_name", name: "Currently With", Component: TableMultiselect, print: true, isExcelField: true, excelExport: (elt: any) => elt['current_user_name'] }, */
  /* { id: "last_updated", name: "Last Updated", filter: delta, Component: TableDate, Cell: RenderedDate, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['last_updated'] ? '-' : moment(elt['last_updated']).format('DD/MM/YYYY') }, */
  /* { id: "plan_start", name: "Plan Start", filter: delta, Component: TableDate, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_start'] ? '-' : moment(elt['plan_start']).format('DD/MM/YYYY') },
   * { id: "actual_start", name: "Actual Start", filter: delta, Component: TableDate, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['actual_start'] ? '-' : moment(elt['actual_start']).format('DD/MM/YYYY') },
   * { id: "plan_end", name: "Plan End", filter: delta, print: true, Component: TableDate, Cell: RenderedDate, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_end'] ? '-' : moment(elt['plan_end']).format('DD/MM/YYYY') },
   * { id: "actual_end", name: "Actual End", filter: delta, Cell: RenderedDate, Component: TableDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['actual_end'] ? '-' : moment(elt['actual_end']).format('DD/MM/YYYY') }, */
];

const FormDataAggregate = memo(({ formId, fieldPositions }: { formId: string; fieldPositions: { [K: string]: number; } }) => {
  const [data, setData] = useState<any[]>([]);
  const [headersData, setHeadersData] = useState<{}>({});
  const [columns, setColumns] = useState<any[]>([]);
  const [rawData, setRawData] = useState<{ [K: string]: any; }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const store = useStore();
  const { search, ...location } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const params = useCustomQueryParameters(FormDataColumns, searchParams);
  const columnsConfig = useMemo(() => transduce(
    columns,
    compose(
      /* filter(({ print: printV }) => (print === "print" ? !!printV : true)), */
      /* filter(({ id }) =>
       *   id === "pour_number" ? phase === "structures" ? true : false : true
       * ), */
      map(({ id, name, Component, Cell, print, accessor, headerObj, minWidth, sort, ...rest }) => ({
        name,
        Header:
          Component && print !== "print"
            ? Component
            : name,
        minWidth: minWidth || 150,
        id,
        accessor: accessor || id,
        headerObj,
        Cell: !!Cell ? Cell : DefaultCells,
        data: headersData[id] ? sort ? headersData[id].slice().sort(sort) : headersData[id] : null,
        ...rest
      })),
      /* partitionBy(({ group }) => group), */
      /* map(([{ group, ...rest }, ...arr]) => ({ Header: group, columns: [{ ...rest, group }, ...arr] })) */
    ),
    toArray()
  ), [columns, headersData]);

  useEffect(() => {
    if (!client.client) {
      client();
    }
    store.exportTableFilters.clearTableFiltersData();
    if (client.client && formId && store.projectInfo.currentProject?.properties?.length) {
      setLoading(true);
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_ALL_FORMS_DATA, variables: { formId } })
        .subscribe(({ data: { formData }, loading }) => {
          if (!loading) {
            if (formData?.length) {
              let formFields: string[] = [];
              formData?.forEach(({ inputData }) => {
                for (let k in inputData) {
                  if (!formFields.includes(k)) { formFields.push(k); }
                }
              });
              setRawData(() => formData.reduce((acc, { activity, ...rest }) => ({ ...acc, [activity]: { ...rest, activity } }), {}));
              store.exportTableFilters.changeHeaderDataLoading(true)
              const headersQuery = (client.client as ApolloClient<NormalizedCacheObject>)!
                .watchQuery({ query: GET_ACTIVITY_HEADERS_FOR_FORM, variables: { where: { unit_activity_id: { _in: formData.map(({ activity }) => activity) }, block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } } } } })
                .subscribe(({ data, loading: innerLoading }) => {
                  if (!innerLoading) {
                    setHeadersData(() => FormDataColumns.reduce((acc, { id, Component }) =>
                      Component
                        ? { ...acc, [id]: data[id] && data[id].length > 0 ? data[id] : null }
                        : acc,
                      {}
                    ));
                    store.exportTableFilters.changeHeaderDataLoading(false);
                  }
                }, err => {
                  console.error(err);
                  store.exportTableFilters.changeHeaderDataLoading(false);
                });
              const fieldsQuery = (client.client as ApolloClient<NormalizedCacheObject>)!
                .watchQuery({ query: GET_ALL_FORM_FIELDS, variables: { formFields } })
                .subscribe(({ data: { formFields }, loading: innerLoading }) => {
                  if (!innerLoading) {
                    const formFieldsConfig = formFields?.map(
                      ({ id, data: { label, input_type: inputType, ...rest } }) => ({
                        id,
                        name: label,
                        print: true,
                        value: label,
                        group: "Form",
                        position: fieldPositions[id] === 0 ? fieldPositions[id] : (fieldPositions[id] || __MAX),
                        inputType,
                        ...(inputTypes[inputType] && inputTypes[inputType](id)) || __defaultExport(id),
                        ...rest
                      })
                    ).sort(__sortingStrategy) || [];
                    setColumns(() => [...FormDataColumns, ...formFieldsConfig]);
                  }
                }, err => { console.error(err); setLoading(false); });
            }
          }
        }, err => { console.error(err); setLoading(false); });
      return () => { query.unsubscribe(); }
    }
  }, [client, formId, fieldPositions]);
  useEffect(() => {
    if (client.client && Object.keys(rawData).length && params) {
      const activities = Object.keys(rawData);
      setLoading(true);
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_ACTIVITY_DATA_FOR_FORM, variables: { ...params, where: { ...(params.where || {}), unit_activity_id: { _in: activities } } } })
        .subscribe(({ data: { activities: fetchedActivities }, loading: innerLoading }) => {
          if (!innerLoading) {

            setData(() => fetchedActivities.map((obj, i) => ({ ...obj, ...(rawData[obj['unit_activity_id']]?.inputData || {}) })));
            setLoading(false);
          }
        }, err => { console.error(err); setLoading(false); });
      return () => { query.unsubscribe(); }
    }
  }, [formId, rawData, params]);

  useEffect(() => { store.exportTableFilters.setColumns(columnsConfig); store.exportTableFilters.setColumnConfig(columnsConfig); }, [formId, columnsConfig]);
  useEffect(() => {
    store.exportTableFilters.setThinData(data);
    store.exportTableFilters.setZeroRows(data.length <= 0 ? true : false);
  }, [formId, data]);

  return (
    <div
      style={{
        backgroundColor: "#faf9f9",
        flexDirection: "column",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexBasis: "auto",
        flexShrink: 1,
        overflow: "auto"
        // padding: "0px 20px 0 24px",
      }}
    >

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          // overflow: "scroll",
          flexGrow: 1,
          flexShrink: 1,
          // height:"70vh"
        }}
      >
        {loading ? <LoadingSpinner bgColor={'#faf9f9'} width={"80%"} /> : <ThinTable columns={columnsConfig} data={data} print="false" />}
      </div>
    </div >
  )
});

const FormDataAggregatePage = observer(() => {
  const [forms, setForms] = useState<{ id: string; name: string; }[]>([]);
  const [formFieldsPositions, setFormFieldsPositions] = useState<{
    [K: string]: {
      [L: string]: number;
    }
  }>({});
  const [loading, setLoading] = useState(false);
  const { formId } = useParams();
  const classes = useStyles();
  const { push } = useHistory();
  const [state, FormIdFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, apply: true, reset: false }, () => formId ? [formId] : []);
  const store = useStore();
  useEffect(() => { if (!formId) { putAsync(relay, { type: 'replace', payload: [] }); } }, [formId]);
  useEffect(() => { if (!client.client) { client(); } }, [client]);
  const disabled = useMemo(() => !state.length || state[0] === formId, [state, formId]);
  const applyHandler = useCallback(() => {
    if (state.length) { push(`/${__SECTION}/${state[0]}`); putAsync(relay, { type: "toggle", payload: false }); }
  }, [state]);
  useEffect(() => {
    if (client.client) {
      setLoading(true);
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_FORMS, variables: { propertyList: store.projectInfo.currentProject?.properties.map(({ id }) => id) || [] }, errorPolicy: "all" });
      const observable = query.subscribe(({ data: { forms }, loading, errors }) => {
        if (errors) { console.error(errors); setLoading(false); }
        else if (!loading && forms) {
          let formsState: { id: string; name: string; }[] = [], formFieldsPositionState: { [K: string]: { [L: string]: number; } } = {}, i = 0, len = forms.length;
          for (; i < len; i++) {
            const { id, form_config: { name, fieldOrders } } = forms[i];
            formsState.push({ id, name });
            formFieldsPositionState[id] = fieldOrders.reduce((acc, { fieldId, position }) => ({ ...acc, [fieldId]: position === 0 ? position : (position || __MAX) }), {});
          }
          setForms(() => formsState);
          setFormFieldsPositions(() => formFieldsPositionState);
          /* setForms(() => (forms.map(({ id, form_config: { name } }) => ({ id, name })) || []));
           * setFormFieldsPositions(() => forms.reduce((acc, {id, fieldOrders}) => ({...acc, [id]: fieldOrders.reduce((ac, ({fieldId, position}) => ({...ac, [fieldId]: position || 100}), {}))}), {})); */
          setLoading(false);
        }
      });
      return () => { observable.unsubscribe(); };
    }
  }, []);
  return (<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
    <div style={{ display: "flex", flexShrink: 1 }}>
      <div className={classes.formDataDiv}>
        <div style={{ display: "flex", flexShrink: 1 }}>
          <FormIdFilter
            options={forms}
            Render={RadioButton}
            callback={applyHandler}
            disabled={disabled}
            title={<SelectedState title="Form" state={state} filterType="form" />}
            formData={true}
          />
        </div>
        <div className={classes.arrowDiv}>
          <img src={RightArrow} height={20} width={20} alt="previous_page" />
        </div>
        <div className={classes.formDiv}>
          <div className={classes.formSpan}>{formId ? forms.find(({ id }) => id === formId)?.name : "Please select a Form"}</div>
        </div>
      </div>
    </div>
    <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1, overflow: "scroll" }}>
      {!loading && !formId && (
        <div className={classes.initialMessageDiv}>
          View and export data for various forms
        </div>
      )
      }
      {!loading && formId && (
        <FormDataAggregate
          formId={formId}
          fieldPositions={formFieldsPositions[formId] || {}}
        />
      )}
    </div>
  </div>);
});

export default FormDataAggregatePage;
