import React, { useEffect, useMemo } from 'react'
import { Avatar, FormControl, Select, MenuItem } from '@material-ui/core'
import useCommonStyles from "../popUp/summaryPopUp/CommonPopUpStyles";
import useStyles from "./CommonTableStyles";
import { useStore } from "../../models/ProvideModel";
import moment from 'moment';
import { calcStatus, getStatusName, isDelayedEnd, isDelayedStart } from '../../utils/utils';
import { borderColors, colors } from '../../utils/constants';
import { ProgressBar, ProgressBarProps } from '../progressBar/ProgressBar';
import { IWPU } from '../WPUPopup/WPUPopup';
import WPU from '../WPUPopup/WPUPopup';
import FormDataPage from "../formData/formData"
import { useObserver } from 'mobx-react-lite';
import plusIcon from "../../assets/images/plus-icon.svg";
import RenderImagesCell from '../RenderImagesCell';

// * gives you snags count in string, if you pass in snag_count object
export function snagCountToString({ snag_counts }) {
  if (snag_counts)
    return `${snag_counts.count_open} / ${snag_counts.count_open + snag_counts.count_closed + snag_counts.count_for_review}`
  return `0 / 0`
}

export function activityStatusToString({ completed, in_progress, total }) {
  return `Completed: ${completed} | OnGoing: ${in_progress} | Not Started: ${total - (completed + in_progress)}`
}

export function DaysFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  const dates = [
    { name: "This Week", id: moment().startOf('day').diff(moment().startOf('week'), 'days').toString(), value: [moment().startOf('week').format('MM-DD-YYYY'), moment().startOf('day').format('MM-DD-YYYY')] },
    { name: "This Month", id: moment().startOf('day').diff(moment().startOf('month'), 'days').toString(), value: [moment().startOf('month').format('MM-DD-YYYY'), moment().startOf('day').format('MM-DD-YYYY')] },
    { name: "This Quarter", id: moment().startOf('day').diff(moment().startOf('quarter'), 'days').toString(), value: [moment().startOf('quarter').format('MM-DD-YYYY'), moment().startOf('day').format('MM-DD-YYYY')] },
    { name: "Last 7 days", id: "7", value: [moment().startOf('day').subtract(7, 'day').format('MM-DD-YYYY'), moment().startOf('day').format('MM-DD-YYYY')] },
    { name: "Last 30 days", id: "30", value: [moment().startOf('day').subtract(30, 'day').format('MM-DD-YYYY'), moment().startOf('day').format('MM-DD-YYYY')] },
    { name: "Last 90 days", id: "90", value: [moment().startOf('day').subtract(90, 'day').format('MM-DD-YYYY'), moment().startOf('day').format('MM-DD-YYYY')] },
  ];
  const classes = useStyles({});
  return (
    <div className={classes.statusFilter}>
      <label className={classes.progressOver}>{render('Header')}</label>
      <FormControl className={classes.formControl}>
        <Select
          value={filterValue}
          renderValue={() => dates.find(option => option.id === filterValue)?.value.join(" ") || "AllDays"}
          onChange={e => setFilter(e?.target?.value || undefined)}
          className={dates.find(option => option.id === filterValue)?.value ? classes.dateField : classes.inputField}
          displayEmpty
          // MenuProps={MenuProps}
          placeholder="Select"
        >
          <MenuItem disabled className={classes.selectStatus}>
            <span>{render('Header')}</span>
          </MenuItem>
          <MenuItem value={undefined} className={classes.selectedItem}>
            <span>All Days</span>
          </MenuItem>

          {dates.map((option: any, i) =>
            <MenuItem key={option.name} value={option.id} className={classes.selectedItem}>{option.name}</MenuItem>
          )
          }
          ))
        </Select>
      </FormControl>
    </div>
  )
}


export function inBetweenPastDays(rows, id, filterValue) {
  const today = moment().startOf('day');
  const lastDate = moment().subtract(filterValue, 'days')
  return rows.filter(row => {
    const rowValue = row.values[id]
    return moment(rowValue).isBetween(lastDate, today)
  })
}

export function StatusRender({ cell: { value } }) {
  const commonClasses = useCommonStyles();
  const classes = useStyles({});
  return (
    <div className={classes.statusCell}>
      < Avatar className={
        (value === 'Completed' || value === '8_done') ? commonClasses.activityCompletedAvtar : '' ||
          (value === 'On Going' || value === '3_active') ? commonClasses.activityOngoingAvtar : '' ||
            (value === 'Not Started' || value === '1_planned') ? commonClasses.activityNotStartedAvtar : ''
      } ></Avatar >
      <span> {
        (value === 'Completed' || value === '8_done') ? "Completed" : null ||
          (value === 'On Going' || value === '3_active') ? "On Going" : null ||
            (value === 'Not Started' || value === '1_planned') ? "Not Started" : null}
      </span>
    </div>

  )
}

export const RenderedStatus = (props) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles({});
  return (
    <div className={classes.statusCell}>
      < Avatar className={
        (props.cell.value === 'Completed' || props.cell.value === '8_done') ? commonClasses.activityCompletedAvtar : '' ||
          (props.cell.value === 'On Going' || props.cell.value === '3_active') ? commonClasses.activityOngoingAvtar : '' ||
            (props.cell.value === 'Not Started' || props.cell.value === '1_planned') ? commonClasses.activityNotStartedAvtar : ''
      } ></Avatar >
      <span> {
        (props.cell.value === 'Completed' || props.cell.value === '8_done') ? "Completed" : null ||
          (props.cell.value === 'On Going' || props.cell.value === '3_active') ? "On Going" : null ||
            (props.cell.value === 'Not Started' || props.cell.value === '1_planned') ? "Not Started" : null}
      </span>
    </div>
  )
};

export const RenderedDate = (props) => {
  const date = !!props.cell.value && moment(props.cell.value).add((props.column.id === "actual_start_spl" || !moment(props.cell.value).hasOwnProperty('_tzm')) ? 330 : 0, "minutes" )
  // console.log('props.column.id',props.column.id, date, props.cell.value)
  return (<div style={{ display: 'flex', flexDirection: 'column' }}>
    <div>{!!date && date.format("D MMM YYYY")}</div>
    <div style={{ fontSize: '0.7em', color: "#808080" }}>{!!date && date.format("hh:mm A")}</div>
  </div>)
}

export function DateRender({ cell: { value } }) {
  return (!!value && moment(value).format("D MMM YYYY"))
}

export function DateRenderDelay({ cell: { row: { values }, value, } }) {

  if (!!value) {
    if (values.actualEnd) {
      if (moment(value).isBefore(values.actualEnd)) return <span style={{ color: 'red' }}>{moment(value).format("D MMM YYYY")}</span>
      else return <span>{moment(value).format("D MMM YYYY")}</span>
    }
    else {
      if (moment().isAfter(moment(value))) return <span style={{ color: 'red' }}>{moment(value).format("D MMM YYYY")}</span>
      else return <span>{moment(value).format("D MMM YYYY")}</span>
    }
  } else return null
}

export const DateRenderDelayGen = ({ planned, actual }) => {


  if (!!planned) {
    if (!!actual && moment(planned).isBefore(actual)) {
      return (<span style={{ color: 'red' }}>{moment(planned).format("D MMM YYYY")}</span>)
    }
    else {
      if (moment(planned).isBefore(moment())) return (<span style={{ color: 'red' }}>{moment(planned).format("D MMM YYYY")}</span>)
      else return (<span>{moment(planned).format("D MMM YYYY")}</span>)
    }
  } else return <span> - </span>
}

export const CombinedPlanDateRenderDelay = ({ cell: { row: { values: { planStart, planEnd, actualEnd } } } }) => {
  return (<>
    {!!planStart ? moment(planStart).format("D MMM YYYY") : '-'} / <DateRenderDelayGen planned={planEnd} actual={actualEnd} />
  </>)
}

export function CombinedActualDateRenderDelay({ cell: { row: { values: { actualStart, actualEnd } } } }) {
  return `${(!!actualStart ? moment(actualStart).format("D MMM YYYY") : '-')} / ${(!!actualEnd ? moment(actualEnd).format("D MMM YYYY") : '-')}`
}

interface IColumns {
  cell: {
    row: {
      values: {
        plan_end: any;
        plan_start: any;
        actual_end: any;
        actual_start: any;
      };
    };
    column: {
      inputType: any;
    };
    value: any;
  };
  row: {
    original: {
      status: string;
    };
  };
  children?: React.ReactNode;
}

/**
 * Common delayed Component 
 */
export const EndDelayed = (props: any) => {
  if (props.isEndDelayed) {
    return <rect width={props.width} height={props.height} x={props.x} y={props.y} className={props?.classes?.filled} />;
  } else return null
}

export const StartDelayed = (props: any) => {
  if (props.isStartDelayed) {
    return <circle cx={props.cx} cy={props.cy} r={props.r} className={props?.classes?.filled} />;
  } else return null
}

/**
 * render status with delayed marker 
 */
export const StatusWithDelayed = (props: IColumns) => {
  const classes = useStyles({});
  const { plan_end, plan_start, actual_end, actual_start } = props?.cell?.row?.values;
  const isEndDelayed = isDelayedEnd(plan_end, actual_end);
  const isStartDelayed = isDelayedStart(plan_start, actual_start);
  const store = useStore();

  return (
    <div className={classes.statusCell}>
      <div>
        <svg width={37} height={20} viewBox="0 0 37 20">
          <g transform="scale(1,-1) translate(0,-20)">
            <rect
              x={0}
              y={0}
              width={37}
              height={20}
              fill={colors[getStatusName(props.row.original.status)]}
              strokeWidth={1}
              stroke={borderColors[getStatusName(props.row.original.status)]}
            >
              <animate attributeName="width" from="0" to="37" dur="1s" fill="freeze" begin='0s' repeatCount="1" />
            </rect>
          </g>
          <EndDelayed width={6} height={12} x={25} y={4} classes={classes} isEndDelayed={isEndDelayed} />
          <StartDelayed cx={10} cy={10} r={4.5} classes={classes} isStartDelayed={isStartDelayed} />
        </svg>
      </div>
      <div style={{ marginLeft: "12px", display: 'flex', flexDirection: 'column', alignSelf: 'start' }}>
        {props.cell.value && (props.cell.value.toString() === "1_planned" || props.cell.value.toString() === "3_active" || props.cell.value.toString() === "8_done") ? <span style={{ fontSize: 14 }}>{props.cell.value && getStatusName(props.cell.value.toString())}</span> :
          props.cell.value === "Completed" && store.params.section === "unit-act-info" ? <span style={{ fontSize: 14 }}>All Steps Completed</span> : <span style={{ fontSize: 14 }}>{props.cell.value}</span>}
        {
          (isStartDelayed || isEndDelayed) ?
            <span style={{ color: '#ff7777', fontSize: 12 }}>Delayed:{isStartDelayed && ' Start'}{(isStartDelayed && isEndDelayed) && ' & '}{isEndDelayed && ' End'} </span>
            : null
        }
      </div>
    </div>
  )
}

export const DateTimeFormat = (props: IColumns) => {
  const classes = useStyles({});
  const { inputType } = props?.cell?.column;

  const dateTime = (type, value) => {
    if (type === "date") {
      return value?.length ? "Date  - " + moment(value).format("D MMM YYYY") : "—";
    }
    else if (type === "time") {
      return value?.length ? "Time  - " + moment(value).format("hh:mm:ss a") : "—";
    }
    else {
      return value?.length ? "Date  - " + moment(value).format("D MMM YYYY") + " ," + "Time  - " + moment(value).format("hh:mm:ss a") : "—";
    }
  }

  return (
    <div className={classes.statusCell} style={{ textTransform: "none" }}>
      {dateTime(inputType.toLowerCase(), props.cell.value)}
    </div>
  )
}

export const RenderImage = (props: IColumns) => <RenderImagesCell images={props.cell.value} />
/**
 * render status with delayed marker 
 */
export const StatusWithDelayedNumbers = (props) => {
  const classes = useStyles({});
  const { plan_end, plan_start, actual_end, actual_start } = props?.cell?.row?.values;
  const { completed, not_started, in_progress, total } = props?.row?.original
  const status = useMemo(() => calcStatus(completed, in_progress, total), [completed, in_progress, total])
  const isEndDelayed = isDelayedEnd(plan_end, actual_end);
  const isStartDelayed = isDelayedStart(plan_start, actual_start);

  return (
    <div className={classes.statusCell}>
      <div>
        <svg width={37} height={20} viewBox="0 0 37 20">
          <g transform="scale(1,-1) translate(0,-20)">
            <rect
              x={0}
              y={0}
              width={37}
              height={20}
              fill={colors[status]}
              strokeWidth={1}
              stroke={borderColors[status]}
            >
              <animate attributeName="width" from="0" to="37" dur="1s" fill="freeze" begin='0s' repeatCount="1" />
            </rect>
          </g>
          <EndDelayed width={6} height={12} x={25} y={4} classes={classes} isEndDelayed={isEndDelayed} />
          <StartDelayed cx={10} cy={10} r={4.5} classes={classes} isStartDelayed={isStartDelayed} />
        </svg>
      </div>
      <div style={{ marginLeft: '12px', display: 'flex', flexDirection: 'column', alignSelf: 'start' }}>
        <span style={{ fontSize: 14 }}>{status}</span>
        {
          (isStartDelayed || isEndDelayed) ?
            <span style={{ color: '#ff7777', fontSize: 12 }}>Delayed:{isStartDelayed && ' Start'}{(isStartDelayed && isEndDelayed) && ' & '}{isEndDelayed && ' End'} </span>
            : null
        }
      </div>
    </div>
  )
}
export const FormData = (props: any) => {
  const [filterModalState, setFilterModalState] = React.useState(false);
  const store = useStore();
  const classes = useStyles();

  const closeModel = () => {
    setFilterModalState(false)
  }
  const editTest = !props.row?.original[props.column.value].length && (props.row?.original['current_user_id'] === store.auth.userId) && (["RW", "WRITE"].includes(props.row?.original['form_permission']?.action));
  return useObserver(() => (
    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
      <div key={props.row?.original[props.column.value][0]?.form_id} style={{ cursor: "pointer", color: "rgb(0, 94, 255)" }} onClick={() => setFilterModalState(true)}>
        {props.row?.original[props.column.value].map(key => key.form_config.form_description).join(', ')}
      </div>
      {editTest && (<a
        href={`/form-data-write/${props.row?.original['unit_activity_id']}/${props.row?.original['form_permission']?.form_id}`}
        className={classes.linkStyle}
      >Add Data</a>)}
      {filterModalState ? <FormDataPage unitActivityId={props.row?.original['unit_activity_id']} editableForm={props.row?.original['form_permission']?.form_id || null} formDataProps={props.row?.original[props.column.value]} onSelectLanguage={closeModel} /> : null}
    </div>
  ));
}
export const WorkProgressPercentage = (props: any) => {
  const styles = useStyles();
  const progress = props.row.original.progress;
  const [filterModalState, setFilterModalState] = React.useState(false);
  const wpuProbs: IWPU = {
    id: props.row.original.unit_activity_id,
    unit: props.row.original.unit_name,
    activity: props.row.original.activity_name,
    status: props?.cell?.row?.values.status_desc,
    progress: progress,
  };
  const pbProbs: ProgressBarProps = {
    value: progress,
    status: props?.cell?.row?.values.status_desc,
    primary: "#ffc400",
    background: "#ffffff",
    default: "#34d1ae",
    divider: "#ffc400",
  };

  const wpucomponent = () => {
    setFilterModalState(true)
  }
  const closeModel = () => {
    setFilterModalState(false)
  }
  return useObserver(() => (
    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {progress === 0 && (
          <div>-</div>
        )}
        {progress > 0 && (
          <ProgressBar {...pbProbs} />
        )}
        {progress > 0 && (
          <div onClick={wpucomponent} style={{ marginLeft: "10px", marginTop: "3px", cursor: "pointer" }}>
            <img src={plusIcon} alt="" />
          </div>
        )}
      </div>
      {progress > 0 && (<div className={styles.progressValueCont}>{progress}%</div>)}
      {filterModalState ? <WPU wpuProbs={wpuProbs} onSelectLanguage={closeModel} /> : null}
    </div>
  ));
}
